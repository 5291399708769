<script>
import ExperienceRecord from '@/components/general/ExperienceRecord'
import InputField from '@/components/general/InputField'
import TextArea from '@/components/general/TextArea'
import SectionBlock from '@/components/general/SectionBlock'
import SelectField from '@/components/general/SelectField'
import Combobox from '@/components/general/Combobox'
import Autocomplete from '@/components/general/Autocomplete'
import countryListPt from '@/support/countryinfo/country-pt-br.json'
import countryListEn from '@/support/countryinfo/countries_en.json'
import { countryFromCode, getCountries } from '@/support/countryinfo/country-codes.js'
import { email } from 'vuelidate/lib/validators'

export default {
  components: {
    ExperienceRecord,
    InputField,
    TextArea,
    SectionBlock,
    SelectField,
    Combobox,
    Autocomplete
  },
  name: 'ProfileInfoForm',
  props: {
    value: {
      type: Object
    },
    validations: {
      type: Object
    },
    showPreferredAreas: {
      type: Boolean,
      default: true
    },
    themeColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      formData: this.value,
      profileInfo: {},
      universitySearch: '',
      universityOptions: [],
      courseSearch: '',
      courseOptions: [],
      companySearch: '',
      companyOptions: [],
      jobTitleSearch: '',
      jobTitleOptions: [],
      positionsCategory: [],
      citySearch: '',
      cityIdOptions: {},
      languageList: {},
      showImportResumeModal: false,
      tempArea: null,
      regionOptions: [
        { text: this.$t('global:country.brazil'), value: 'BRA' },
        { text: this.$t('global:country.united.states'), value: 'USA' }
      ],
      autocompleteRegionOptions: ['BRA', 'PRT', 'USA', 'ITA'],
      socialMediaOptions: {
        Linkedin: 'https://www.linkedin.com/',
        Instagram: 'https://www.instagram.com/',
        Twitter: 'https://www.twitter.com/',
        Facebook: 'https://www.facebook.com/',
        GitHub: 'https://www.github.com/',
        Tiktok: 'https://www.tiktok.com/',
        Dribbble: 'https://www.dribbble.com/',
        Behance: 'https://www.behance.com/',
        Blog: 'https://',
        Site: 'https://'
      }
    }
  },
  computed: {
    selectedCountry () {
      return countryFromCode(this.formData.countryCode)
    },
    countryOptions () {
      if (this.getUser.language === 'pt') return countryListPt
      return countryListEn
    },
    countryPhoneOptions () {
      const countriesInfo = getCountries()
      const list = Object.keys(countriesInfo).map(el => {
        return { name: countriesInfo[el].countryName, dialCode: countriesInfo[el].dialCode, countryCode: countriesInfo[el].countryCode }
      })
      return list
    },
    getUser () {
      return this.$store.getters.getUser
    },
    selectProficiencyOptions () {
      const levels = ['elementary', 'limited_working', 'professional_working', 'native_or_bilingual']
      return levels.map(level => ({ text: this.$t(level), value: level }))
    },
    selectLanguagesOptions () {
      return Object.keys(this.languageList).map(el => ({ text: this.languageList[el], value: el }))
    },
    showEmail () {
      if (this.$route.name === 'newaccount.home.firstAccess.profile') return false
      return true
    }
  },
  methods: {
    availableSocialMediaOptions (actualType) {
      let userMedias = this.formData.socialMedia.map(el => el.type)
      userMedias = userMedias.filter(x => x !== actualType)
      return Object.keys(this.socialMediaOptions).filter(x => !userMedias.includes(x))
    },
    availableLanguageOptions (actualType) {
      let userLanguages = this.formData.languages.map(el => el.name)
      userLanguages = userLanguages.filter(x => x !== actualType)
      const languageOptions = Object.keys(this.languageList).map(el => ({ text: this.languageList[el], value: el }))
      return languageOptions.filter(x => !userLanguages.includes(x.value))
    },
    updateProfessionalDateValidations (index) {
      this.validations.formData.professionalExperiences.$each[index].$touch()
      this.validations.formData.professionalExperiences.$each[index].company.$reset()
      this.validations.formData.professionalExperiences.$each[index].title.$reset()
      this.validations.formData.professionalExperiences.$each[index].dateInit.$reset()
      this.validations.formData.professionalExperiences.$each[index].actualWork.$reset()
    },
    updateAcademicDateValidations (index) {
      this.validations.formData.academicExperiences.$each[index].$touch()
      this.validations.formData.academicExperiences.$each[index].institution.$reset()
      this.validations.formData.academicExperiences.$each[index].title.$reset()
      this.validations.formData.academicExperiences.$each[index].dateInit.$reset()
      this.validations.formData.academicExperiences.$each[index].isStudying.$reset()
    },
    onChangeCountry () {
      this.formData.city = ''
      this.formData.cityId = ''
      this.formData.cityOptions = []
      this.cityIdOptions = {}
      this.formData.pcdType = ''
      this.formData.isPCD = false
      this.formData.pcdCID = ''
      this.formData.needAdaptation = false
      this.formData.pcdAdaptation = ''
    },
    cancelEditProfessionalExperience (value, index) {
      this.formData.professionalExperiences[index] = { ...value }
      this.formData.professionalExperiences = [...this.formData.professionalExperiences]
    },
    cancelEditAcademicExperience (value, index) {
      this.formData.academicExperiences[index] = { ...value }
      this.formData.academicExperiences = [...this.formData.academicExperiences]
    },
    cancelEditLanguage (value, index) {
      this.formData.languages[index] = { ...value }
      this.formData.languages = [...this.formData.languages]
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    addNewSocialMedia () {
      this.formData.socialMedia.push({ type: this.socialMediaOptions[0], value: '' })
    },
    addNewProfessionalExperience () {
      this.formData.professionalExperiences.push({ new: true })
    },
    addNewAcademicExperience () {
      this.formData.academicExperiences.push({ new: true })
    },
    addPreferredArea (item) {
      setTimeout(() => {
        this.tempArea = null
      }, 10)
      if (this.formData.preferredAreas.findIndex(i => i === item) !== -1) return
      this.formData.preferredAreas.push(item)
    },
    removeLanguage (languageIndex) {
      this.formData.languages = this.formData.languages.filter((_, index) => index !== languageIndex)
    },
    addNewLanguage () {
      this.formData.languages.push({ new: true })
    },
    removeSocialMedia (socialMediaIndex) {
      this.formData.socialMedia = this.formData.socialMedia.filter((_, index) => index !== socialMediaIndex)
    },
    removePreferredArea (preferredAreaIndex) {
      this.formData.preferredAreas = this.formData.preferredAreas.filter((_, index) => index !== preferredAreaIndex)
    },
    removeProfessionalExperience (professionalExperienceIndex) {
      this.formData.professionalExperiences = this.formData.professionalExperiences.filter((_, index) => index !== professionalExperienceIndex)
    },
    removeAcademicExperience (academicExperienceIndex) {
      this.formData.academicExperiences = this.formData.academicExperiences.filter((_, index) => index !== academicExperienceIndex)
    },
    createCategoryObject (array) {
      const categories = {}
      array.forEach((category) => {
        categories[category.id] = category.title
      })
      return categories
    },
    formatDateToTimeStamp (myDate) {
      myDate = myDate.split('/')
      var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]).toISOString()
      return newDate
    },
    getCategoryById (id) {
      const categories = this.createCategoryObject(this.positionsCategory)
      return categories[id]
    },
    getLanguageById (id) {
      const languageList = this.languageList
      return languageList[id]
    },
    formatMonthYearToTimestamp (date) {
      const month = date.split('/')[0]
      const year = date.split('/')[1]
      return `${year}-${month}-01T00:00:00.000Z`
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('attemptGetLanguages', this.getUser.language),
      this.$store.dispatch('attemptGetPositionsCategory', this.getUser.language)]).then(([data1, data2]) => {
      this.languageList = data1
      this.positionsCategory = data2
    })
  },
  watch: {
    'formData.email' (input) {
      if (email(input) && input.length > 0) {
        this.debounceEvent(async () => {
          try {
            this.formData.emailResponse = await this.$store.dispatch('attemptVerifyEmail', input)
          } catch (err) {
            this.formData.emailResponse = err.response
          }
        }, 400)
      }
    },
    'formData.isPCD' (val) {
      if (val === false) {
        this.formData.pcdType = ''
        this.formData.pcdCID = ''
        this.formData.needAdaptation = false
        this.formData.pcdAdaptation = ''
      }
    },
    'formData.needAdaptation' (val) {
      if (val === false) {
        this.formData.pcdAdaptation = ''
      }
    },
    'formData.city': {
      handler (val) {
        if (this.formData.cityOptions.length === 0 && val) {
          this.formData.cityOptions.push(val)
        }
      },
      immediate: true
    },
    'formData.phone': {
      handler (val) {
        if (this.formData.countryCode === 'br') {
          if (val) {
            const formatedPhone = val.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2').replace(/(\d{5})(\d{4})(\d)/, '$1-$2').slice(0, 14)
            this.$nextTick(() => {
              this.formData.phone = formatedPhone
            })
          }
        }
      },
      immediate: true
    },
    value: {
      handler (val, oldval) {
        this.formData = val
      },
      deep: true
    },
    formData: {
      handler (val, oldval) {
        this.$emit('input', val)
      },
      deep: true
    },
    citySearch (input) {
      if (!input || input.length < 3) {
        this.formData.cityOptions = []
        this.formData.cityIdOptions = {}
        return
      }
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attemptGetCountryCities', { country: this.formData.countryAbbr, query: input })
        if (options.length) {
          this.formData.cityOptions = options.map(item => (item.name))
          const cityIdOptionsObject = {}
          options.map(item => {
            cityIdOptionsObject[item.name] = item.id
          })
          this.formData.cityIdOptions = { ...cityIdOptionsObject }
        }
      }, 300)
    },
    universitySearch (input) {
      if (!input || input.length < 3) this.universityOptions = []
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attemptGetUniversity', input)
        if (options.length) this.universityOptions = options.map(item => (item.name))
      }, 300)
    },
    courseSearch (input) {
      if (!input || input.length < 3) return
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attemptGetCourse', { name: input, language: this.getUser.language })
        if (options.length) this.courseOptions = options.map(item => (item.name))
      }, 300)
    },
    companySearch (input) {
      if (!input || input.length < 3) return
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attemptGetCompanies', input)
        if (options.length) this.companyOptions = options.map(item => (item.name))
      }, 300)
    },
    jobTitleSearch (input) {
      if (!input || input.length < 3) return
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attemptGetJobTitle', input)
        if (options.length) this.jobTitleOptions = options.map(item => (item.name))
      }, 300)
    }
  }
}
</script>
<template>
  <div>
    <div>
      <section-block title="account.form:contact.data.title">
        <input-field v-show="showEmail" v-model="formData.email" maxLength="160" outlined persistent-placeholder :validation="validations.formData.email" :label="$t('settings.profile.labels:email')"></input-field>
        <input-field v-model="formData.phone" @keypress="isNumber($event)" maxLength="160" outlined persistent-placeholder :validation="validations.formData.phone" :label="$t('settings.profile.labels:contact.phone')">
        <template slot="prepend-inner">
          <select-field class="country-options"
              v-model="formData.countryCode"
              :items="countryPhoneOptions"
              item-text="name"
              item-value="countryCode"
              flat solo
            >
              <template v-slot:item="slotProps" >
                  <i :class="['iti-flag', slotProps.item.countryCode, 'spacing']"></i>
                {{slotProps.item.name}}
                +{{slotProps.item.dialCode}}
              </template>
              <template slot="prepend-inner">
                <i :class="['iti-flag', formData.countryCode , 'flag-spacing']"></i>
              </template>
            </select-field>
        </template>
        </input-field>
      </section-block>
      <section-block title="account.form:personal.data.title">
        <input-field v-model="formData.name" maxLength="160" outlined persistent-placeholder :validation="validations.formData.name" :label="$t('settings.profile.labels:name.personal')"></input-field>
        <input-field v-model="formData.lastName" maxLength="160" outlined persistent-placeholder :validation="validations.formData.lastName" :label="$t('settings.profile.labels:lastName')"></input-field>
        <select-field outlined  v-model="formData.region" :items="regionOptions" :label="this.$t('settings.profile.labels:region')" persistent-placeholder></select-field>
        <select-field outlined v-model="formData.countryAbbr" @change="onChangeCountry" :validation="validations.formData.countryAbbr" :items="countryOptions" :label="this.$t('settings.profile.labels:country')" persistent-placeholder></select-field>
        <template v-if="autocompleteRegionOptions.includes(formData.countryAbbr)">
          <autocomplete
                  color="red"
                  :search-input.sync="citySearch"
                  :items="formData.cityOptions"
                  v-model="formData.city"
                  outlined
                  clearable
                  @change="formData.cityId = formData.cityIdOptions[formData.city]"
                  :filter="item=>item"
                  persistent-placeholder
                  :validation="validations.formData.city"
                  :no-data-text="$t('global:no.data.text')"
                  :label="$t('settings.profile.labels:city')"
                  >
          </autocomplete>
        </template>
        <input-field v-else v-model="formData.city" maxLength="160" outlined persistent-placeholder :validation="validations.formData.city" :label="$t('settings.profile.labels:city')"></input-field>
        <input-field v-show="formData.region === 'BRA'" v-model="formData.birthdate" :validation="validations.formData.birthdate" outlined persistent-placeholder :label="$t('settings.profile.labels:birthdate')" :mask="'##/##/####'"></input-field>
        <template v-if="formData.region === 'BRA'" >
          <v-checkbox v-model="formData.isPCD" :label="$t('settings.profile.labels:isPCD')" :color="themeColor ? themeColor : getPrimaryColor"></v-checkbox>
          <template v-if="formData.isPCD">
            <text-area v-model="formData.pcdType" outlined persistent-placeholder :validation="validations.formData.pcdType" :label="$t('settings.profile.labels:disability.type')"></text-area>
            <input-field maxLength="160" v-model="formData.pcdCID" outlined  persistent-placeholder :validation="validations.formData.pcdCID" :label="$t('settings.profile.labels:cid.code')"></input-field>
            <v-checkbox v-model="formData.needAdaptation" :label="$t('settings.profile.labels:need.adaptation')" :color="themeColor ? themeColor : getPrimaryColor"></v-checkbox>
            <text-area v-if="formData.needAdaptation" v-model="formData.pcdAdaptation" outlined persistent-placeholder :validation="validations.formData.pcdAdaptation" :label="$t('settings.profile.labels:adaptation.needed')"></text-area>
          </template>
        </template>
      </section-block>
      <section-block title="account.form:social.media.data.title">
        <template>
          <i></i>
        <v-row class="social-media--item" v-for="(item, index) in formData.socialMedia" :key="item.type">
          <v-col class="py-0" cols="12" sm="4" md="4" >
            <select-field v-model="item.type" outlined small @change="(value) => item.value = socialMediaOptions[value]"
              :items="availableSocialMediaOptions(item.type)">
            </select-field>
          </v-col>
          <v-col class="py-0" cols="12" sm="8" md="8">
            <input-field maxLength="160" class="input-social" dense v-model="item.value" outlined persistent-placeholder :validation="validations.formData.socialMedia.$each[index]" :label="$t('settings.profile.labels:url.link')" append-icon="mdi-close" @click:append="removeSocialMedia(index)"></input-field>
          </v-col>
        </v-row>
        </template>
        <template v-slot:bottom>
          <v-btn class="btn transform-unset" :color="themeColor ? themeColor : getPrimaryColor" outlined @click="addNewSocialMedia"><v-icon size="14" class="mr-2">mdi-plus</v-icon>{{ $t('settings.profile.labels:social.media.add') }}</v-btn>
        </template>
      </section-block>
      <section-block title="account.form:professional.data.title">
        <input-field maxLength="160" v-model="formData.professionalTitle" outlined persistent-placeholder :validation="validations.formData.professionalTitle" :label="$t('settings.profile.labels:professional.title')"></input-field>
        <text-area v-model="formData.professionalDescription" outlined persistent-placeholder :validation="validations.formData.professionalDescription" :label="$t('settings.profile.labels:professional.resume')"></text-area>
        <select-field v-show="showPreferredAreas" v-model="tempArea" outlined small :items="positionsCategory.map(el => ({text: el.title, value: el.id}))" @change="addPreferredArea" :validation="validations.formData.preferredAreas" :placeholder="$t('settings.profile.labels:preferred.area')"></select-field>
        <div class="preferred-areas-list">
          <v-chip v-for="(item, index) in formData.preferredAreas" :key="item">{{ getCategoryById(item) }}<v-icon class="remove-item" @click="removePreferredArea(index)">mdi-close</v-icon></v-chip>
        </div>
      </section-block>
      <section-block title="account.form:professional.experience.title">
        <v-checkbox v-if="formData.professionalExperiences.length === 0" v-model="formData.hasProfessionalExp" :label="$t('settings.profile.labels:professional.experience')" :color="themeColor ? themeColor : getPrimaryColor"></v-checkbox>
        <span class="form-input-message" v-if="validations.formData.hasProfessionalExp.$error"><v-icon class="form-input-icon">mdi-information</v-icon>{{ $t('global:validation.required') }}</span>
        <experience-record
          v-for="(exp, index) in formData.professionalExperiences"
          :validation="validations.formData.professionalExperiences.$each[index]"
          :key="index"
          :title="exp.company"
          :subTitle="exp.title"
          :dateInit="exp.dateInit"
          :dateEnd="!exp.actualWork ?exp.dateEnd : $t('global:currently')"
          :item="exp"
          @cancelEdit="(value) => cancelEditProfessionalExperience(value, index)"
          @remove="removeProfessionalExperience(index)"
        >
          <template v-slot:fields>
            <v-col cols="12" sm="12">
              <combobox
                  color="red"
                  maxLength="160"
                  :search-input.sync="companySearch"
                  :items="companyOptions"
                  v-model="exp.company"
                  outlined
                  clearable
                  persistent-placeholder
                  :validation="validations.formData.professionalExperiences.$each[index].company"
                  :no-data-text="$t('global:no.data.text')"
                  :label="$t('experience.record.modal:company.name')"
                  >
                </combobox>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <combobox
                  color="red"
                  maxLength="160"
                  :search-input.sync="jobTitleSearch"
                  :items="jobTitleOptions"
                  v-model="exp.title"
                  persistent-placeholder
                  outlined
                  clearable
                  :validation="validations.formData.professionalExperiences.$each[index].title"
                  :no-data-text="$t('global:no.data.text')"
                  :label="$t('experience.record.modal:professional.title')">
                </combobox>
            </v-col>
            <v-col cols="12" sm="12" md="12">
            <v-textarea
              outlined
              v-model="exp.description"
              persistent-placeholder
              :no-data-text="$t('global:no.data.text')"
              :label="$t('experience.record.modal:company.description')"
            ></v-textarea>
            </v-col>
            <v-col cols="12" sm="12" md="12">
            <input-field
              v-model="exp.dateInit"
              placeholder="MM/AAAA"
              outlined persistent-placeholder
              :validation="validations.formData.professionalExperiences.$each[index].dateInit"
              :label="$t('experience.record.modal:date.init')"
              mask="##/####">
            </input-field>
            </v-col>
            <v-checkbox v-model="exp.actualWork" @change="(value)=> {
              if (value) {
                exp.dateEnd = ''
              }
            }"
            :label="$t('settings.profile.labels:actualwork')" :color="themeColor ? themeColor : getPrimaryColor"></v-checkbox>
            <v-col cols="12" sm="12" md="12">
            <input-field
              v-model="exp.dateEnd"
              :disabled="exp.actualWork"
              outlined persistent-placeholder
              placeholder="MM/AAAA"
              hide-validation
              @blur="updateProfessionalDateValidations(index)"
              :label="$t('experience.record.modal:date.end')"
            mask="##/####">
            </input-field>
            <div class="form-input-messages-container" >
              <div v-if="validations.formData.professionalExperiences.$each[index] && validations.formData.professionalExperiences.$each[index].$error">
              <div v-if="!validations.formData.professionalExperiences.$each[index].dateEnd.required && validations.formData.professionalExperiences.$each[index].dateEnd.required !== undefined">
                <v-icon class="form-input-icon">mdi-information</v-icon>
                <span class="form-input-message">{{ $t('global:validation.required') }}</span>
              </div>
              <div v-else-if="!validations.formData.professionalExperiences.$each[index].dateEnd.validFormatMonthYear && validations.formData.professionalExperiences.$each[index].dateEnd.validFormatMonthYear !== undefined">
                <v-icon class="form-input-icon">mdi-information</v-icon>
                <span class="form-input-message" >{{ $t('global:validation.invalid.date')}}</span>
              </div>
              <div v-else-if="!validations.formData.professionalExperiences.$each[index].initEndDate && validations.formData.professionalExperiences.$each[index].initEndDate !== undefined">
                    <v-icon class="form-input-icon">mdi-information</v-icon>
                    <span class="form-input-message" >{{ $t('global:validation.future.date')}}</span>
              </div>
              <div v-else-if="!validations.formData.professionalExperiences.$each[index].dateEnd.limitDateMonthYear && validations.formData.professionalExperiences.$each[index].dateEnd.limitDateMonthYear !== undefined">
                <v-icon class="form-input-icon">mdi-information</v-icon>
                <span class="form-input-message" >{{ $t('global:validation.future.limit.date') }}</span>
              </div>
              </div>
            </div>
            </v-col>
          </template>
        </experience-record>
        <template v-slot:bottom>
          <v-btn class="btn transform-unset" :color="themeColor ? themeColor : getPrimaryColor" outlined @click="addNewProfessionalExperience"><v-icon size="14" class="mr-2">mdi-plus</v-icon>{{ $t('settings.profile.labels:professional.experience.add') }}</v-btn>
        </template>
      </section-block>

      <section-block title="account.form:academic.experience.title">
        <v-checkbox v-if="formData.academicExperiences.length === 0" v-model="formData.hasAcademicExp" :validation="validations.formData.hasAcademicExp" :label="$t('settings.profile.labels:academic.experience')" :color="themeColor ? themeColor : getPrimaryColor"></v-checkbox>
        <span class="form-input-message" v-if="validations.formData.hasAcademicExp.$error"><v-icon class="form-input-icon">mdi-information</v-icon>{{ $t('global:validation.required') }}</span>
        <experience-record
          v-for="(exp, index) in formData.academicExperiences"
          :validation="validations.formData.academicExperiences.$each[index]"
          :key="index"
          :title="exp.institution"
          :subTitle="exp.title"
          :dateInit="exp.dateInit"
          :dateEnd="!!exp.dateEnd ? exp.dateEnd : $t('global:currently')"
          :item="exp"
          @cancelEdit="(value) => cancelEditAcademicExperience(value, index)"
          @remove="removeAcademicExperience(index)"
        >
          <template v-slot:fields>
            <v-col cols="12" sm="12" md="12">
              <combobox
                  v-model="exp.institution"
                  maxLength="160"
                  :search-input.sync="universitySearch"
                  :items="universityOptions"
                  :no-data-text="$t('global:no.data.text')"
                  color="red"
                  outlined
                  primary
                  clearable
                  no-filter
                  persistent-placeholder
                  :validation="validations.formData.academicExperiences.$each[index].institution"
                  :label="$t('experience.record.modal:institution.name')">
                  >
                </combobox>
            </v-col>
              <v-col cols="12" sm="12" md="12">
              <combobox
                  color="red"
                  maxLength="160"
                  :search-input.sync="courseSearch"
                  :items="courseOptions"
                  v-model="exp.title"
                  outlined
                  clearable
                  persistent-placeholder
                  :validation="validations.formData.academicExperiences.$each[index].title"
                  :label="$t('experience.record.modal:academic.title')"
                  :no-data-text="$t('global:no.data.text')">
                </combobox>
              </v-col>
             <v-col cols="12" sm="12" md="12">
            <input-field
              v-model="exp.dateInit" outlined
              persistent-placeholder
              placeholder="AAAA"
              :label="$t('experience.record.modal:date.init')"
              :validation="validations.formData.academicExperiences.$each[index].dateInit"
              mask="####">
            </input-field>
            </v-col>
            <v-checkbox v-model="exp.isStudying" @change="(value)=> {
              if (value) {
                exp.dateEnd = ''
              }
            }"
              :label="$t('settings.profile.labels:is.studying')" :color="themeColor ? themeColor : getPrimaryColor"></v-checkbox>
            <v-col cols="12" sm="12" md="12">
            <input-field
              v-model="exp.dateEnd"
              :disabled="exp.isStudying"
              outlined persistent-placeholder
              :label="$t('experience.record.modal:date.end')"
              placeholder="AAAA"
              @blur="updateAcademicDateValidations(index)"
              hide-validation
              mask="####">
            </input-field>
            <div class="form-input-messages-container">
              <div v-if="validations.formData.academicExperiences.$each[index] && validations.formData.academicExperiences.$each[index].$error">
              <div v-if="!validations.formData.academicExperiences.$each[index].dateEnd.required && validations.formData.academicExperiences.$each[index].dateEnd.required !== undefined">
                <v-icon class="form-input-icon">mdi-information</v-icon>
                <span class="form-input-message">{{ $t('global:validation.required') }}</span>
              </div>
              <div v-else-if="!validations.formData.academicExperiences.$each[index].dateEnd.validFormatMonthYear && validations.formData.academicExperiences.$each[index].dateEnd.validFormatMonthYear !== undefined">
                <v-icon class="form-input-icon">mdi-information</v-icon>
                <span class="form-input-message" >{{ $t('global:validation.invalid.date')}}</span>
              </div>
              <div v-else-if="!validations.formData.academicExperiences.$each[index].initEndDateYear && validations.formData.academicExperiences.$each[index].initEndDateYear !== undefined">
                    <v-icon class="form-input-icon">mdi-information</v-icon>
                    <span class="form-input-message" >{{ $t('global:validation.future.date')}}</span>
              </div>
              <div v-else-if="!validations.formData.academicExperiences.$each[index].dateEnd.limitDateYear && validations.formData.academicExperiences.$each[index].dateEnd.limitDateYear !== undefined">
                <v-icon class="form-input-icon">mdi-information</v-icon>
                <span class="form-input-message" >{{ $t('global:validation.future.limit.date') }}</span>
              </div>
              </div>
            </div>
            </v-col>
          </template>
        </experience-record>
        <template v-slot:bottom>
          <v-btn class="btn transform-unset" :color="themeColor ? themeColor : getPrimaryColor" outlined @click="addNewAcademicExperience"><v-icon size="14" class="mr-2">mdi-plus</v-icon>{{ $t('settings.profile.labels:academic.experience.add') }}</v-btn>
        </template>
      </section-block>

      <section-block title="account.form:languages.title">
        <template>
          <i></i>
          <experience-record
            v-for="(lang, index) in formData.languages"
            :validation="validations.formData.languages.$each[index]"
            :key="index"
            :title="getLanguageById(lang.name)"
            :dateInit="$t(lang.proficiency)"
            :item="lang"
            @cancelEdit="(value) => cancelEditLanguage(value, index)"
            @remove="removeLanguage(index)"
          >
          <template v-slot:fields>
            <select-field v-model="lang.name" :items="availableLanguageOptions(lang.name)" :validation="validations.formData.languages.$each[index].name" outlined persistent-placeholder :label="$t('experience.record.modal:language.name')"></select-field>
            <select-field v-model="lang.proficiency" outlined persistent-placeholder :items="selectProficiencyOptions" :validation="validations.formData.languages.$each[index].proficiency" :label="$t('experience.record.modal:language.proficiency')"></select-field>
          </template>
        </experience-record>
      </template>
        <template v-slot:bottom>
          <v-btn class="btn transform-unset" :color="themeColor ? themeColor : getPrimaryColor" outlined @click="addNewLanguage"><v-icon size="14" class="mr-2">mdi-plus</v-icon>{{ $t('settings.profile.labels:languages.add') }}</v-btn>
        </template>
      </section-block>
    </div>
    <import-resume-modal v-if="showImportResumeModal" @close="showImportResumeModal = false"></import-resume-modal>
  </div>
</template>

<style lang="scss">
@import '@/assets/styles/flags.scss';
.spacing {
  margin-right: 15px;
}
.flag-spacing {
  margin-top: 10px;
}
.v-input__prepend-inner {
  bottom: 10px;
  margin-top: 0px !important;
}
.country-options{
  .validation--wrapper {
    .validation--message {
      display:none
    }
    .v-input {
      .v-input__control {
        .v-input__slot {
          .v-select__slot {
            .v-select__selections {
              display: none;
            }
          }
          .v-text-field__slot {
            padding-left: 15px !important;
          }
          .v-input__prepend-inner {
            margin-top: 0px !important;
          }
        }
      }
        .v-menu__content {
          min-width: 500px !important;
          top: 50px !important;
      }
    }
  }
}
.country-options {
  width: 60px;
}
.form-input-messages-container {
  min-height: 30px;
  text-align: left;
}
.form-input-message {
    font-size: 12px;
    color: $warning-medium;
}
.form-input-icon {
  color: $warning-medium;
  margin-right: 4px;
  &.v-icon {
    color: $warning-medium;
    font-size: 18px;
  }
}
.preferred-areas-list {
  .v-chip {
    background: #FFFFFF !important;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 8px;
    border-radius: 9999px;
    color: #454545;
    font-weight: bold;
    margin-right: 10px;
    .v-icon.remove-item {
      cursor: pointer;
      background: #454545;
      color: #FFFFFF;
      border-radius: 50px;
      margin-left: 6px;
      height: 20px;
      width: 20px;
      font-size: 18px;
    }
  }
}
.profile-form--actions {
  text-align: left;
  margin: 40px 0 80px;
}
</style>
