<script>
export default {
  name: 'ImportResume',
  data () {
    return {
      ready: false,
      statusError: null,
      fileInfos: {
        name: '',
        size: 0
      },
      actualStep: 0,
      tempArea: null,
      resume: null,
      socialMediaOptions: [
        'Facebook',
        'Linkedin',
        'Instagram',
        'Twitter'
      ],
      areaSelectionOptions: [
        'Tecnologia',
        'Agricultura',
        'Designer',
        'Testes'
      ],
      resumeCounter: 0,
      selectedTip: '',
      tips: [
        'import.resume.modal:import.analysis.tips1',
        'import.resume.modal:import.analysis.tips2',
        'import.resume.modal:import.analysis.tips3',
        'import.resume.modal:import.analysis.tips4',
        'import.resume.modal:import.analysis.tips5',
        'import.resume.modal:import.analysis.tips6',
        'import.resume.modal:import.analysis.tips7',
        'import.resume.modal:import.analysis.tips8',
        'import.resume.modal:import.analysis.tips9',
        'import.resume.modal:import.analysis.tips10'
      ],
      formData: {
        countryCode: '',
        name: '',
        lastName: '',
        city: '',
        birthdate: '',
        region: '',
        skills: [],
        countryAbbr: '',
        email: '',
        phone: '',
        isPCD: false,
        pcdType: '',
        pcdCID: '',
        needAdaptation: false,
        pcdAdaptation: '',
        socialMedia: [],
        professionalTitle: '',
        professionalDescription: '',
        preferredAreas: [],
        hasProfessionalExp: true,
        professionalExperiences: [],
        hasAcademicExp: true,
        academicExperiences: [],
        languages: [],
        cityOptions: [],
        cityIdOptions: []
      }
    }
  },
  props: {
    themeColor: {
      type: String,
      default: ''
    },
    imported: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getUser () {
      return this.$store.getters.getUser
    },
    status () {
      return this.actualStep === 0 ? 'fileSelection' : this.actualStep === 1 ? 'analysis' : this.actualStep === 2 ? 'finished' : 'error'
    }
  },
  methods: {
    storeResume (e) {
      this.resume = e.target.files[0]
      if (this.resume.type === 'application/pdf') {
        this.actualStep = 1
        this.$emit('step', 1)
        this.submitResume()
      } else {
        this.handleCheckError('415')
      }
    },
    async submitResume () {
      const formData = new FormData()
      formData.append('enviroment', 'web')
      formData.append('resume', this.resume)
      this.fileInfos = {
        name: document.getElementById('resumeUpload').files[0].name,
        size: Number.parseFloat(document.getElementById('resumeUpload').files[0].size / 100)
      }
      try {
        const response = await this.$store.dispatch('attemptImportUserResume', formData)
        if (response.status === 201) {
          if (!response || response.id) return null
          const wait = timeToDelay => new Promise(resolve => setTimeout(resolve, timeToDelay))
          const checkResume = async (parseId, started) => {
            try {
              this.snackbarManagement()
              const data = await this.$store.dispatch('attemptGetResumeResult', parseId)
              if (data.status === 'error') {
                this.handleCheckError(data.status)
              }
              if (data && data.status === 'complete') {
                this.snackbar = false
                this.formData = { ...this.formData, ...this.convertFormData(data.data), name: this.getUser.firstName, lastName: this.getUser.lastName, email: this.getUser.email, region: this.getUser.region }
                this.$emit('step', 2)
                this.$emit('overwrite-form-data', this.formData)
              }
              if (data && data.status === 'pending') {
                const total = new Date().getTime() - started.getTime()
                if (total > 181000) {
                  this.handleCheckError('timeout')
                  return
                }
                await wait(5000)
                return checkResume(parseId, started)
              }
            } catch (err) {
              this.handleCheckError(err.message)
            }
          }
          return checkResume(response.data.parse_id, new Date())
        }
      } catch (err) {
        this.handleCheckError(err.message)
      }
    },
    snackbarManagement () {
      if (this.resumeCounter % 2 === 0) {
        this.snackbar = false
        setTimeout(() => {
          const tipOrder = Math.floor(this.resumeCounter / 2)
          const index = tipOrder < this.tips.length ? tipOrder : tipOrder % this.tips.length
          this.selectedTip = this.tips[index]
          this.snackbar = true
        }, 500)
      }
      this.resumeCounter++
    },
    handleBackPage () {
      this.$emit('stepCange', { step: 1 })
      this.$router.push({ name: 'newaccount.home.firstAccess.skills', query: { ...this.$route.query } })
    },
    handleCheckError (message) {
      this.snackbar = false
      this.actualStep = 3
      this.$emit('step', 3)
      switch ((message.includes('400') || message.includes('404')) ? '404' : message.includes('413') ? '413' : message.includes('415') ? '415' : message.includes('error') ? 'error' : message) {
        case '413':
          this.statusError = { title: 'import.resume.modal:import.error.title', subtitle: 'import.resume.modal:error.banner.message.large' }
          break
        case '415':
          this.statusError = { title: 'import.resume.modal:import.error.title', subtitle: 'import.resume.modal:error.banner.message.format' }
          break
        case 'error':
          this.statusError = { title: 'import.resume.modal:import.error.title', subtitle: 'import.resume.modal:error.banner.message.format' }
          break
        case 'timeout':
          this.statusError = { title: 'import.resume.modal:import.timeout.title', subtitle: 'import.resume.modal:timeout.banner.message.format' }
          break
        case '404':
          this.statusError = { title: 'import.resume.modal:import.error.title', subtitle: 'import.resume.modal:error.banner.message' }
          break
      }
    }
  },
  mounted () {
    if (this.imported) this.$refs.file.click()
  }
}
</script>
<template>
  <v-card class="import-resume" outlined :loading="status === 'analysis'" >
    <v-alert v-if="status === 'error'" dense color="red" outlined text type="warning" icon="mdi-alert"> {{ $t(statusError.title)}}<p class="subtitle-alert mb-0">{{ $t(statusError.subtitle) }}</p>
    </v-alert>
    <div class="import-resume-card">
      <div class="import-resume-message">
        <p v-if="status === 'fileSelection'"><v-icon class="mr-1">mdi-check</v-icon>{{ $t('import.resume.modal:import.instruction.1') }}</p>
        <p v-if="status === 'fileSelection'"><v-icon class="mr-1">mdi-check</v-icon>{{ $t('import.resume.modal:import.instruction.2') }}</p>
        <p v-if="status === 'fileSelection'"><v-icon class="mr-1">mdi-check</v-icon>{{ $t('import.resume.modal:import.instruction.3') }}</p>
        <p v-if="status === 'analysis'" class="subtitle mb-0" ><span class="mr-1"></span>{{ $t('first.access.import.resume:import.title.analyzing.card') }}</p>
        <p v-if="status === 'error'"><span class="mr-1"></span>{{ $t('first.access.import.resume:import.title.error.card') }}</p>
      </div>
      <div v-if="status === 'fileSelection' || status === 'error'" class="import-resume--bottom-actions mt-4">
        <input @change="storeResume" id="resumeUpload" type="file" ref="file" accept="application/pdf" hidden>
        <v-btn id="importBtn" class="btn transform-unset" :color="themeColor ? themeColor : getPrimaryColor" dark @click="$refs.file.click()" >
          <v-icon class="mr-2" dense color="#fff">mdi-file-upload-outline</v-icon>
          {{ $t( status === 'error' ? 'global:select.another.file' : 'global:select.file') }}
        </v-btn>
      </div>
      <div v-if="status === 'analysis'" class="file-infos">
        <v-chip  class="infos mt-3" large label color="white">
            <v-avatar class="mr-1" left>
              <v-icon class="icon-file" :color="themeColor ? themeColor : getPrimaryColor">mdi-file-pdf</v-icon>
            </v-avatar>
            <p class="name-file mb-0 mr-2">{{ this.fileInfos.name }}</p>
            <p class="size-file mb-0">{{ this.fileInfos.size }}KB</p>
        </v-chip>
        <v-snackbar
          v-model="snackbar"
          color="white"
          rounded="pill"
          :timeout="0"
        >
          <div class="snackbar-wrapper">
            <div style="display:flex; align-items:start">
              <v-img contain height="40px" width="40px" src="/assets/images/tip.png"></v-img>
            </div>
            <p class="remove-margin">{{ $t(selectedTip)}}</p>
          </div>
        </v-snackbar>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.import-resume {
  .snackbar-wrapper {
    .remove-margin {
      margin-bottom: 0px;
    }
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    p {
      margin-left: 10px;
    }
  }
  .v-alert__content{
    text-align: initial;
    font-weight: 600;
  }
  .subtitle-alert{
    text-align: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
  }
  .import-resume-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    background: #FAFBFC;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    .import-resume-message {
      text-align: center;
      p{
        margin-bottom: 0;
      }
    }
    .file-infos {
      display: flex;
      align-items: center;
      justify-content: center;
      .infos {
        display: flex;
        pointer-events: none;
        padding: 0px 4px;
        height: 24px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        .icon-file {
          font-size: 19px;
        }
        .name-file {
          font-weight: 700;
          font-size: 14px;
          color: #212121;
        }
        .size-file {
          font-weight: 700;
          font-size: 14px;
          color: #A0A0A0;
        }
      }
    }
  }
}
</style>
