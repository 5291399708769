<script>
import VueCropper from 'vue-cropperjs'
// import ValidationTemplate from '@/components/general/ValidationTemplate'
import 'cropperjs/dist/cropper.css'
export default {
  components: {
    VueCropper
  },
  name: 'ImageUpload',
  data () {
    return {
      isLoading: false,
      selectedImage: null,
      selectedFile: null,
      isSelecting: false,
      dialog: false,
      hover: false
    }
  },
  props: {
    validation: {
      type: Object
    },
    manualValidation: {
      type: Boolean,
      default: false
    },
    hideValidation: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number,
      default: () => 21 / 9
    },
    imageBackground: {
      type: [String, Blob],
      default: null
    },
    defaultImageBackground: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 750
    },
    height: {
      type: Number,
      default: 180
    },
    vWidth: {
      type: Number
    },
    vHeight: {
      type: Number
    },
    rounded: {
      type: Boolean,
      default: false
    },
    zoomable: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    textButton: {
      type: String,
      default: 'profile.picture:change.picture'
    },
    themeColor: {
      type: String,
      default: ''
    }
  },
  watch: {
    imageBackground () {
      this.setSelectedImage()
    }
  },
  methods: {
    onUploadClick () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged (e) {
      const file = e.target.files[0]
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('global:validation.mimetype')
        })
        return
      }
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        if (typeof file === 'object') reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    updateImage () {
      this.selectedImage = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', '0.9')
      if (!this.selectedImage) return
      var byteString = atob(this.selectedImage.split(',')[1])
      var mimeString = this.selectedImage.split(',')[0].split(':')[1].split(';')[0]
      // Validar com vuelidate
      if (mimeString !== 'image/jpeg' && mimeString !== 'image/png') return
      var ab = new ArrayBuffer(byteString.length)
      var ia = new Uint8Array(ab)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      var bb = new Blob([ab], { type: mimeString })
      const formData = new FormData()
      formData.append('url', process.env.VUE_APP_HIRE_API + 'v1/store/images/users/avatar')
      formData.append('file', bb)
      formData.append('name', 'avatar.png')
      formData.append('type', 'avatar')
      this.$emit('image-loading', true)
      this.isLoading = true
      this.$store.dispatch('attemptPostUserProfilePhoto', formData).then(data => {
        this.$emit('upload-image', data.image)
        this.$emit('image-loading', false)
        this.isLoading = false
      })
    },
    resetInput (skipSelectedFile = false) {
      if (!skipSelectedFile) this.selectedFile = null
      this.$refs.cropper.replace(null)
      this.$refs.uploader.value = ''
    },
    deleteImage () {
      this.selectedFile = null
      this.selectedImage = null
      this.$emit('removeImage')
    },
    setSelectedImage () {
      if (typeof this.imageBackground === 'string') {
        this.selectedImage = this.imageBackground.trim().length > 0 ? this.imageBackground : null
      }
    },
    clearSelectedImage () {
      this.selectedImage = null
    }
  },
  created () {
    this.setSelectedImage()
  }
}
</script>

<template>
    <div class="all-container"
      :style="{width: (vWidth || width) + 'px', height: (vHeight || height) + 'px'}"
      :class="{'rounded-image': rounded}"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div class="upload-actions-div">
        <v-btn class="btn transform-unset" :class="{ 'white--text': !outlined }"  :outlined="outlined" :disabled="isLoading || disabled" @click="onUploadClick" :color="themeColor ? themeColor : getPrimaryColor">
          <v-icon v-show="icon" class="mr-2" dense>{{ icon }}</v-icon>
          {{ $t(textButton) }}
        </v-btn>
      </div>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChanged"
      >
      <v-dialog v-model="dialog" width="700" persistent content-class="cropper-container">
        <div class="cropper">
          <VueCropper
            v-show="selectedFile"
            ref="cropper"
            :src="selectedImage"
            alt="Source Image"
            :aspectRatio="aspectRatio"
            :imgStyle="{objectFit: 'cover'}"
            :zoomable="zoomable"
          ></VueCropper>
        </div>
        <div class="cropper-tools">
          <v-btn text small @click="resetInput(), (dialog = false)">
            <v-container>
              <span class="bold">{{$t('global:cancel')}}</span>
            </v-container>
          </v-btn>
          <v-btn text small @click="updateImage(), (dialog = false)">
            <v-container>
              <span class="bold">{{$t('global:cut')}}</span>
            </v-container>
          </v-btn>
        </div>
      </v-dialog>
    </div>
</template>

<style lang="scss">
.all-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;
  &.rounded-image {
    border-radius: 100px;
    .image-upload-overlap,
    .upload-actions-div,
    .image-upload {
      border-radius: 100px;
    }
    .upload-actions-div {
      flex-direction: row;
    }
  }
}
.image-upload {
  opacity: 0.5;
  object-fit: cover;
  max-width: 100%;
}
.image-upload-btn-text {
  color: #FFFFFF;
}
.cropper {
  overflow: hidden;
  max-height: 80vh;
  > div {
    height: 80vh;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
.cropper-tools {
  background: $neutral-white;
  text-align: right;
  .v-btn.v-size--small {
    color: $primary-medium;
    height: 40px;
  }
}
.upload-actions-div {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 20;
  > * {
    margin: 0 5px;
    font-weight: bold;
  }
}
.image-upload--btn-icon {
  transform: rotate(180deg);
}
.v-btn.delete-action.v-size--small {
  min-width: 10px;
}
@media only screen and (max-width: 768px) {
  .cropper-container {
    position: relative;
  }
  .cropper-tools {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    background: $primary-medium;
    .v-btn.v-size--small {
      min-width: 50%;
      height: 50px;
      color: $neutral-white;
    }
  }
}
</style>
