<script>
import ProfilePictureManage from '@/components/settings/components/ProfilePictureManage'
import ProfileInfoForm from '@/components/settings/components/ProfileInfoForm.vue'
import ImportResume from './ImportResume'
import Loading from '@/components/general/Loading'
import { required, email, requiredIf, maxValue } from 'vuelidate/lib/validators'
import { getCountries } from '@/support/countryinfo/country-codes.js'

export default {
  name: 'Profile',
  components: {
    ProfilePictureManage,
    ProfileInfoForm,
    ImportResume,
    Loading
  },
  template: {
    type: Object
  },
  data () {
    return {
      noImported: null,
      userAvatar: null,
      ready: false,
      actualStep: 0,
      initialFormData: null,
      initialSocialMedia: null,
      createResume: 'import',
      socialMediaOptions: {
        Linkedin: 'https://www.linkedin.com/',
        Instagram: 'https://www.instagram.com/',
        Twitter: 'https://www.twitter.com/',
        Facebook: 'https://www.facebook.com/',
        GitHub: 'https://www.github.com/',
        Tiktok: 'https://www.tiktok.com/',
        Dribbble: 'https://www.dribbble.com/',
        Behance: 'https://www.behance.com/',
        Blog: 'https://',
        Site: 'https://'
      },
      formData: {
        countryCode: '',
        name: '',
        lastName: '',
        city: '',
        birthdate: '',
        region: '',
        countryAbbr: '',
        email: '',
        phone: '',
        isPCD: false,
        pcdType: '',
        pcdCID: '',
        needAdaptation: false,
        pcdAdaptation: '',
        socialMedia: [],
        professionalTitle: '',
        professionalDescription: '',
        hasProfessionalExp: false,
        professionalExperiences: [],
        hasAcademicExp: false,
        academicExperiences: [],
        languages: [],
        cityOptions: []
      }
    }
  },
  validations () {
    const validateBirthDate = (value) => {
      if (value === '') return true
      if (value.split('/').length !== 3) return false
      const [day, month, year] = value.split('/')

      if (!(day.length === 2) || !(month.length === 2) || !(year.length === 4)) return false

      const birthDate = new Date()
      birthDate.setMonth(month - 1)
      birthDate.setDate(day)
      birthDate.setFullYear(year)

      if (birthDate.getTime() > new Date().getTime()) return false
      const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
      if (!value.match(regex)) return false
      return true
    }
    const validatePhoneDDD = (input) => {
      if (this.formData.countryCode !== 'br') return true
      if (!input) return false
      const value = input.replace('(', '').replace(')', '').replace(' ', '')
      if (value.length === 11) {
        var validDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99]
        const ddd = value.slice(0, 2)
        if (validDDD.indexOf(parseInt(ddd)) !== -1) return true
        return false
      } else return false
    }
    const validatePhoneNumber = (input) => {
      if (this.formData.countryCode !== 'br') return true
      if (!input) return false
      const value = input.replace('(', '').replace(')', '').replace(' ', '')
      if (value.length === 11) {
        var validFirstDigit = ['6', '7', '8', '9']
        const tel = value.slice(2)
        if (validFirstDigit.indexOf(tel[0]) !== -1) {
          return true
        }
        return false
      } else return false
    }
    const hasAcademicExperiencesValidation = (value) => {
      if (this.formData.academicExperiences.length > 0) return true
      return value === true
    }
    const hasProfessionalExperiencesValidation = (value) => {
      if (this.formData.professionalExperiences.length > 0) return true
      return value === true
    }
    const validateSocialMedias = (value) => {
      const secureLink = this.socialMediaOptions[value.type]
      const startingLink = value.value.slice(0, secureLink.length)
      return secureLink === startingLink
    }
    const initEndDateYear = (value) => {
      if (value.actualWork || value.isStudying) return true
      if (!value.dateInit || !value.dateEnd) return true
      const yearInit = parseInt(value.dateInit)
      const yearEnd = parseInt(value.dateEnd)
      if (yearInit > yearEnd) return false
      return true
    }
    const initEndDate = (value) => {
      if (value.actualWork || value.isStudying) return true
      if (!value.dateInit || !value.dateEnd) return true
      const monthInit = parseInt(value.dateInit.split('/')[0])
      const monthEnd = parseInt(value.dateEnd.split('/')[0])
      const yearInit = parseInt(value.dateInit.split('/')[1])
      const yearEnd = parseInt(value.dateEnd.split('/')[1])
      if (yearInit > yearEnd) return false
      if (yearInit === yearEnd && monthInit > monthEnd) return false
      return true
    }
    const validFormatMonthYear = (value) => {
      if (!value) return true
      if (!value.includes('/')) return false
      const month = value.split('/')[0]
      const year = value.split('/')[1]
      if (month.length !== 2 || year.length !== 4) return false
      if (parseInt(month) > 12) return false
      return true
    }
    const limitDateMonthYear = (value) => {
      if (!value) return true
      if (!value.includes('/')) return false
      const actualYear = new Date().getUTCFullYear()
      const actualMonth = ('0' + (new Date().getUTCMonth() + 1)).slice(-2)
      const month = parseInt(value.split('/')[0])
      const year = parseInt(value.split('/')[1])
      if (year > actualYear) return false
      if (year === actualYear && month > actualMonth) return false
      return true
    }
    return {
      formData: {
        name: { required },
        lastName: { required },
        birthdate: { validateBirthDate },
        countryAbbr: '',
        city: { required },
        email: { required, email },
        phone: { required, validatePhoneDDD, validatePhoneNumber },
        isPCD: '',
        pcdType: '',
        pcdCID: '',
        needAdaptation: '',
        pcdAdaptation: '',
        socialMedia: {
          $each: { validateSocialMedias }
        },
        professionalTitle: { required },
        professionalDescription: { required },
        hasProfessionalExp: {
          hasProfessionalExperiencesValidation
        },
        professionalExperiences: {
          $each: {
            initEndDate,
            company: { required },
            title: { required },
            dateInit: { required, validFormatMonthYear, limitDateMonthYear },
            actualWork: '',
            dateEnd: {
              required: requiredIf(function (experience) {
                return !experience.actualWork
              }),
              validFormatMonthYear,
              limitDateMonthYear
            }
          }
        },
        hasAcademicExp: {
          hasAcademicExperiencesValidation
        },
        academicExperiences: {
          $each: {
            initEndDateYear,
            institution: { required },
            title: { required },
            dateInit: {
              required,
              limitDateYear: maxValue(new Date().getUTCFullYear())
            },
            isStudying: '',
            dateEnd: {
              required: requiredIf(function (experience) {
                return !experience.isStudying
              }),
              limitDateYear: maxValue(new Date().getUTCFullYear())
            }
          }
        },
        languages: {
          $each: {
            name: { required },
            proficiency: { required }
          }
        }
      }
    }
  },
  computed: {
    setTheme () {
      return this.getTheme(this.$route.query)
    },
    showInnerCardTitle () {
      let title = ''
      switch (this.actualStep) {
        case 0:
          title = 'first.access.import.resume:import.title.0'
          break
        case 1:
          title = 'first.access.import.resume:import.title.1'
          break
        case 2:
          if (this.createResume === 'create') {
            title = 'first.access.import.resume:import.title.2b'
          } else {
            title = 'first.access.import.resume:import.title.2'
          }
          break
        case 3:
          title = 'first.access.import.resume:import.title.3'
          break
      }
      return title
    }
  },
  methods: {
    countryToCode (country) {
      const countriesInfo = getCountries()
      const referenceObject = {}
      Object.keys(countriesInfo).forEach(el => { referenceObject[el] = countriesInfo[el].dialCode })
      return referenceObject[country]
    },
    getUserProfile () {
      this.$store.dispatch('attemptGetUserProfile').then((data) => {
        this.userAvatar = data.avatar
        this.initialSocialMedia = data.socialLinks ? Object.keys(data.socialLinks)?.map((link) => {
          if (data.socialLinks[link] === '') return
          return { type: this.capitalizeFirstLetter(link), value: data.socialLinks[link] }
        }).filter(link => link !== undefined) : []
        const previousFormData = {
          name: data.personalDetails.firstName || '',
          lastName: data.personalDetails.lastName || '',
          email: data.personalDetails.email || '',
          phone: data.personalDetails.phone || '',
          region: data.personalDetails?.region
        }
        this.formData = { ...this.formData, ...previousFormData, ...this.convertFormData(data) }
        if (this.imported) this.actualStep = 0
        if (previousFormData.phone || !this.imported) this.actualStep = 2
        this.ready = true
      })
    },
    capitalizeFirstLetter (string) {
      if (string.toLowerCase() === 'github') return 'GitHub'
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    updateGlobalInfo () {
      const userObject = {
        email: this.formData.email,
        name: this.formData.name,
        firstName: this.formData.name,
        lastName: this.formData.lastName
      }
      this.$store.dispatch('attemptUpdateUserGlobalInfo', { ...userObject })
      this.$store.dispatch('attemptUpdateIndividualWorkspace', { ...userObject })
      this.$store.dispatch('attemptGetUserWorkspaces')
        .then((data) => {
          const individualWorkspace = data.filter(workspace => workspace.type === 'individual').pop()
          const payload = {
            workspaceId: individualWorkspace.id,
            languages: ['pt-BR', 'en']
          }
          this.$store.dispatch('attemptSubmitContentLanguagePreference', payload)
        })
    },
    checkAcademicFormationsToDelete () {
      const stringfiedFomations = this.formData.academicExperiences.map(el => JSON.stringify(el))
      return this.initialFormData.academicExperiences.map((academicExp) => {
        if (!stringfiedFomations.includes(JSON.stringify(academicExp))) return academicExp.id
      }).filter(el => el !== undefined)
    },
    formatDateToTimeStamp (myDate) {
      myDate = myDate.split('/')
      var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]).toISOString()
      return newDate
    },
    formatYearToTimestamp (date) {
      if (date === '' || !date) return date
      return `${date}-01-01T00:00:00.000Z`
    },
    getUpdatedSocialMedia () {
      const socialMedias = {}
      this.formData.socialMedia.forEach(s => {
        if (!this.initialSocialMedia.some(initial => initial.type === s.type)) this.initialSocialMedia.push(s)
      })
      const removeSocialMedia = this.initialSocialMedia.filter(oldSocial => !this.formData.socialMedia.some(newSocial => newSocial.type === oldSocial.type))
      if (removeSocialMedia.length) {
        this.initialSocialMedia.forEach(socialmedia => {
          socialMedias[socialmedia.type.toLowerCase()] = removeSocialMedia.some(removeSocial => removeSocial.type === socialmedia.type) ? '' : socialmedia.value
        })
        return socialMedias
      }
      this.formData.socialMedia.forEach(socialmedia => {
        socialMedias[socialmedia.type.toLowerCase()] = socialmedia.value
      })
      return socialMedias
    },
    getUpdatedPersonal () {
      const personalInfo = {}
      personalInfo.countryAbbr = this.formData.countryAbbr
      personalInfo.dateOfBirth = this.formData.birthdate !== '' ? this.formatDateToTimeStamp(this.formData.birthdate) : ''
      personalInfo.disability = this.formData.pcdType
      personalInfo.isDisabledPerson = !!this.formData.isPCD
      personalInfo.region = this.formData.region
      personalInfo.firstName = this.formData.name
      personalInfo.lastName = this.formData.lastName
      personalInfo.location = this.formData.city
      personalInfo.locationId = this.formData.cityId
      personalInfo.medicalReportICD = this.formData.pcdCID
      personalInfo.phone = `+${this.countryToCode(this.formData.countryCode)} ${this.formData.phone}`
      personalInfo.specialAdaptationNeeded = this.formData.pcdAdaptation
      personalInfo.title = this.formData.professionalTitle
      personalInfo.description = this.formData.professionalDescription
      return personalInfo
    },
    getUpdatedEducation () {
      if (this.formData.academicExperiences.length === 0) return []
      return this.formData.academicExperiences.map((academicExp) => {
        return { course: academicExp.title, id: academicExp.id, isStudying: academicExp.isStudying, periodFrom: this.formatYearToTimestamp(academicExp.dateInit), periodTo: this.formatYearToTimestamp(academicExp.dateEnd), school: academicExp.institution }
      })
    },
    getUpdatedLanguages () {
      const myObj = {}
      this.formData.languages.forEach(language => {
        myObj[language.name] = language.proficiency
      })
      return myObj
    },
    updateHandler () {
      const updateObject = { filter: 'social-links, work-experience, personal-details, education, categories, languages, skills' }
      updateObject.workExperience = this.formData.professionalExperiences.map((workexp) => {
        return { title: workexp.title, companyName: workexp.company, periodFrom: this.formatMonthYearToTimestamp(workexp.dateInit), periodTo: this.formatMonthYearToTimestamp(workexp.dateEnd), isCurrentWork: workexp.actualWork, id: workexp.id, description: workexp.description }
      })
      updateObject.socialLinks = this.getUpdatedSocialMedia()
      updateObject.personalDetails = this.getUpdatedPersonal()
      updateObject.education = this.getUpdatedEducation()
      updateObject.languages = this.getUpdatedLanguages()
      updateObject.skills = this.formData.skills
      return updateObject
    },
    deletionHandler () {
      const removeObject = { filter: '' }
      if (!this.initialFormData) return removeObject
      const deleteEducation = this.checkAcademicFormationsToDelete()
      if (deleteEducation.length > 0) {
        removeObject.filter += 'education,'
        removeObject.education = deleteEducation
      }
      removeObject.filter = removeObject.filter.slice(0, -1)
      return removeObject
    },
    handleSendForm () {
      this.$v.formData.$touch()
      if (!this.$v.$error) {
        const updateObject = this.updateHandler()
        const deleteObject = this.deletionHandler()
        if (deleteObject.filter !== '') {
          this.$store.dispatch('attemptDeleteUserProfile', deleteObject)
        }
        this.initialSocialMedia = [...this.formData.socialMedia]
        this.$store.dispatch('attemptUpdateUserProfile', updateObject)
        this.updateGlobalInfo()
        this.$router.push({ name: 'newaccount.home.firstAccess.skills', query: { ...this.$route.query } })
      } else {
        if (!this.formData.isPCD) {
          this.$v.formData.pcdType.$reset()
          this.$v.formData.pcdCID.$reset()
        }
        if (!this.formData.needAdaptation) {
          this.$v.formData.pcdAdaptation.$reset()
        }
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('global:validation.form.required')
        })
      }
    },
    formatMonthYearToTimestamp (date) {
      const month = date.split('/')[0]
      const year = date.split('/')[1]
      return `${year}-${month}-01T00:00:00.000Z`
    },
    overwriteFormData (input) {
      const newData = { ...input }
      this.formData = newData
    },
    handleBackPage () {
      this.actualStep = 0
      this.imported = false
    },
    handleCheckStep (step) {
      this.actualStep = step
    }
  },
  created () {
    this.imported = this.$route.params.imported
    const resume = this.$route.params.createResume ? 'create' : 'import'

    if (this.$store.getters.getUserResumeType === undefined || this.$store.getters.getUserResumeType !== resume) {
      this.createResume = resume
      this.$store.commit('updateResumeType', resume)
    } else {
      this.createResume = this.$store.getters.getUserResumeType
    }

    this.$emit('progressChange', { stepProgress: 1 })
    this.getUserProfile()
  }
}
</script>
<template>
  <v-card flat outlined class="import--card mt-3">
    <v-app-bar flat class="import-card--header">
      <p class="text--primary">{{ $t(`${createResume}.resume.modal:title`) }}</p>
    </v-app-bar>
    <loading v-if="!ready"></loading>
    <v-card-text class="import-selection--title" v-if="ready">
      <h4 class="mb-1 mt-8">{{ $t(showInnerCardTitle) }}</h4>
      <p class="subtitle">{{ $t(actualStep === 3 ? 'first.access.import.resume:import.subtitle.2' :
      'first.access.import.resume:import.subtitle.1') }}</p>
      <v-alert v-if="actualStep === 2 && createResume === 'import'" class="mb-0 mt-3" dense color="blue" outlined text
        type="info" icon="mdi-alert-circle">
        {{ $t('first.access.import.resume:import.title.alert')}}
        <p class="subtitle-alert mb-0">{{ $t('first.access.import.resume:import.subtitle.alert') }}</p>
      </v-alert>
    </v-card-text>
    <div class="text--body pa-5" v-if="ready">
      <import-resume class="import-resume" v-if="actualStep !== 2" @overwrite-form-data="overwriteFormData"
        @step="handleCheckStep" :themeColor="setTheme.primaryColor" :imported="imported"></import-resume>
      <profile-picture-manage v-if="actualStep === 2" v-model="userAvatar" :themeColor="setTheme.primaryColor" />
      <profile-info-form v-if="actualStep === 2" v-model="formData" :validations="$v" :showPreferredAreas="false"
        :themeColor="setTheme.primaryColor"></profile-info-form>
    </div>
    <v-card-actions class="import-card--footer" :class="{'form': actualStep === 2, 'no-form': actualStep !== 2,}"
      v-if="ready">
      <v-btn class="btn bold transform-unset next" v-if="actualStep !== 2" :disabled="actualStep === 1"
        @click="actualStep = 2" :color="setTheme.primaryColor" dark>{{ $t('global:skip.step.wizard') }}</v-btn>
      <v-btn class="btn bold transform-unset back" v-if="actualStep === 2" text @click="handleBackPage()">{{
      $t('global:back') }}</v-btn>
      <v-btn class="btn bold transform-unset" v-if="actualStep === 2" @click="handleSendForm()"
        :color="setTheme.primaryColor" dark>{{ $t('global:next.step') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.import--card {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin-bottom: 60px;

  .import-card--header {
    display: flex;
    justify-content: center;
    background-color: #F8F8F8;
    border-bottom: thin solid rgba(0, 0, 0, .12);

    p {
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      color: #212121;
      margin: 0;
    }
  }

  .import-selection--title {
    h4 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #212121;
    }

    .subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #A0A0A0;
      margin-bottom: 0;
    }

    .v-alert__content {
      text-align: initial;
      font-weight: 600;
    }

    .subtitle-alert {
      text-align: start;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }
  }

  .text--body {
    .theme--light.v-sheet--outlined {
      border: none;
    }
  }

  .import-card--footer {
    background-color: #F8F8F8;
    border-top: thin solid rgba(0, 0, 0, .12);
    display: flex;

    &.no-form {
      justify-content: flex-end;
    }

    &.form {
      justify-content: space-between;
    }

    .back {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #7E7D77;
    }

    .next {
      width: 65px;
      height: 36px;
      background: linear-gradient(0deg, rgba(25, 113, 243, 0.1), rgba(25, 113, 243, 0.1)), #FFFFFF;
      border-radius: 4px;
      padding: 8px 16px;
    }
  }
}
</style>
